import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          hi, im dev1ls.
        </p>
        <a
          className="App-link"
          href="https://dev1ls.deno.dev"
          target="_blank"
          rel="noopener noreferrer"
        >
          my blog
        </a>
        <a
          className="App-link"
          href="https://mastodon.sdf.org/@dev1ls"
          target="_blank"
          rel="noopener noreferrer"
        >
          fediverse
        </a>
        <a
          className="App-link"
          href="http://dev1ls.sdf.org/twt.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          twtxt
        </a>

      </header>
    </div>
  );
}

export default App;
